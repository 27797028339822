export const liveData = [
    {
        Nation: "TH",
        title: "สวัสดีปีมังกรทอง!",
        tip:'โปรดคัดลอกโค้ดนี้และไปที่ Shopee เพื่อใช้ได้เลย',
        Shopeecode: 'https://bit.ly/45xpn0T',
        lazadcode: 'SIMP2024D',
        // lazadcode: 'SIMP2024Y',
        text: "ขอให้โชคดีเป็นสองเท่า <br> ในปีมังกรทองนี้!",
        copytip:"คัดลอกสำเร็จ , ไปที่ Shopee เพื่อใช้คูปอง",
    },
    {
        Nation: "VN",
        title: "Chúc mừng năm Rồng!",
        tip:'Vui lòng sao chép mã này và vào Shopee để sử dụng nha',
        Shopeecode: 'https://bit.ly/3twAdqF',
        lazadcode: 'SIMP2024Y',
        text: "Chúc bạn nhân đôi vận may<br>trong năm Rồng nha!",
        copytip:"Sao chép thành công, vui lòng vào Shopee để sử dụng",
    },
    {
        Nation: "ID",
        title: "Selamat Tahun Naga!",
        tip:'Silahkan salin kode ini untuk menggunakannya',
        Shopeecode: 'https://bit.ly/46yXWVG',
        lazadcode: 'SIMP2024Y',
        text: "Semoga Anda mendapat keberuntungan berkali lipat di Tahun Naga!",
        copytip:"Berhasil disalin, mohon buka Shopee untuk menggunakannya",
    },
    {
        Nation: "PH",
        title: "Happy Year of the Dragon! ",
        tip:'Please copy and redeem it in Shopee',
        Shopeecode: 'https://bit.ly/3tFXark',
        lazadcode: 'SIMP2024Y',
        text: "May you always get a plus<br>in the new year with<br>Simplus!",
        copytip:"Copied successfully! Please go to \n Shopee for voucher claim."
    }
    ,
    {
        Nation: "SG",
        title: "Happy Year of the Dragon!",
        tip:'Please copy and redeem it in Shopee',
        Shopeecode: 'https://bit.ly/46AvFyi',
        lazadcode: 'SIMP2024Y',
        text: "May you always get a plus<br>in the new year with<br>Simplus!",
        copytip:"Copied successfully! Please go to \n Shopee for voucher claim."
    }
    ,
    {
        Nation: "MY",
        title: "Happy Year of the Dragon!",
        tip:'Please copy and redeem it in Shopee',
        Shopeecode: 'https://bit.ly/3trujqH',
        lazadcode: 'SIMP2024S',
        // lazadcode: 'SIMP2024D',
        text: "May you always get a plus<br>in the new year with<br>Simplus!",
        copytip:"Copied successfully! Please go to \n Shopee for voucher claim."
    }
    
    
]
