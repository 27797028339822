<template>
  <!-- v-bind:class="{Nation=='HT'?'live-div':'live-div'}" -->
  <div :style="{ height: height + 'px' }" v-bind:class="['live-div', Nation == 'TH' ? 'TH_fontfamily' : ['PH', 'SG', 'MY'].includes(Nation) ? 'enfontfamily' : ['VN'].includes(Nation) ? 'vnfontfamily' : 'fontfamily']">
    <select-country v-if="!isLogin" :labelIndex="zIndex" :isShow="!isLogin" :text="Nation" @change="setnation" />
    <div class="box">
      <div class="livelogo"></div>
      <div class="y1 yy"></div>

      <div v-bind:class="['title fontww', Nation == 'TH' ? 'TH_fontfamily fontwwth' : ['PH', 'SG', 'MY'].includes(Nation) ? 'enfontfamilyaaa' : ['VN'].includes(Nation) ? 'vnfontfamily' : 'fontfamily']">{{ Info.title }}</div>
      <div class="card">
        <div class="card-left" @click="tocoupon(Info.Shopeecode)"></div>
        <div class="card-right">
          <p class="card-info">CODE:</p>
          <strong class="card-time" @click="handCopy(Info.lazadcode, Info.copytip)">{{ Info.lazadcode }}</strong>
        </div>
      </div>
      <div class="tip">
        {{ Info.tip }}
        <p class="tiplog"></p>
      </div>
      <div class="text" v-html="Info.text"></div>
      <div v-bind:class="['y2 yy', height <= 688 ? 'y210' : '']"></div>
      <div class="y3 yy"></div>
    </div>
  </div>
</template>
<script>
import selectCountry from "../../components/select-country/select-country.vue";
import { Icon, Popup, RadioGroup, Radio, Toast } from "vant";
import { langData } from "../../util/lang_config";
import { liveData } from "../../util/new_config";

export default {
  components: {
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    selectCountry,
  },
  data() {
    return {
      iphoneverstr: "",
      showp: false,
      isLogin: false,
      Info: {
        Nation: "PH",
        title: "Happy Year of the Dragon! ",
        tip: "Please copy and redeem it in Shopee",
        Shopeecode: "",
        lazadcode: "",
        text: "May you always get a plus in the new year with Simplus!",
      },
      Nation: "",
      zIndex: "1999",
      height: "100%",
    };
  },
  mounted() {
    this.height = document.documentElement.clientHeight;
  },
  methods: {
    nationchange() {
      // localStorage.setItem('NationALL', this.loginfrom.Nation);
      // this.langInfo = this.setLang();
    },
    setnation(e) {
      if (e) {
        console.log(liveData.filter((res) => res.Nation == e)[0]);
        this.Info = liveData.filter((res) => res.Nation == e)[0];
        this.Nation = e;
        this.isLogin = false;
        this.zIndex = "2016";
        this.height = document.documentElement.clientHeight;
      }
    },
    setLang() {
      let Info = langData.filter((res) => res.Nation == this.Nation);
      return Info == undefined ? langData[0] : Info;
    },
    tocoupon(ulr) {
      window.open(ulr, "_self", "");
    },
    handCopy(text, tip) {
      // text是复制文本
      // 创建input元素
      const el = document.createElement("input");
      // 给input元素赋值需要复制的文本
      el.setAttribute("value", text);
      // 将input元素插入页面
      document.body.appendChild(el);
      // 选中input元素的文本
      el.select();
      // 复制内容到剪贴板
      document.execCommand("copy");
      // 删除input元素
      document.body.removeChild(el);
      Toast({
        message: tip,
      });
    },
    seturl(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less">
@keyframes a1 {
  10% {
    -webkit-transform: rotate(2deg);
  }
  20% {
    -webkit-transform: rotate(5deg);
  }
  40% {
    -webkit-transform: rotate(4deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
.TH_fontfamily {
  font-family: SukhumvitSet !important;
}
.fontfamily {
  font-family: Alegreya Sans Black !important;
}
.vnfontfamily {
  font-family: Times New Roman !important;
}
.enfontfamily {
  font-family: SourceHanSansCN-Regular Normal !important;
}
.enfontfamilyaaa {
  font-family: Alegreya Sans Black Italic !important;
}
.live-div {
  background: #ffedca;
  // height: calc(100vh - 1vh);
  font-weight: bold;
  color: #bc1c21;
  .fontww {
    text-shadow: -1px 0 rgba(255, 255, 255, 1), 0 2px rgba(255, 255, 255, 1), 1px 0 rgba(255, 255, 255, 1), 0 -1px rgba(255, 255, 255, 1);
    width: 90%;
    word-wrap: break-word;
    font-size: 24px;
    // text-shadow:1px 4px 6px #bc1c2173;
  }
  .box {
    height: calc(100% - 1%);
    width: 100% !important;
    min-width: 357px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .yy {
      -webkit-animation: a1 3s infinite alternate ease-in-out; /*设置动画*/
      -webkit-animation-fill-mode: forwards;
    }
    .y1 {
      position: absolute;
      left: -30px;
      top: 20%;
      height: 40px;
      width: 26%;
      background: url("../../assets/images/y1.png") no-repeat;
      background-size: 100% 100%;
      z-index: 1;
    }
    .y2 {
      position: absolute;
      bottom: 30%;
      right: 0%;
      height: 46px;
      width: 22%;
      background: url("../../assets/images/y2.png") no-repeat;
      background-size: 100% 100%;
    }
    .y210 {
      bottom: 20% !important;
    }
    .y3 {
      position: absolute;
      left: 0%;
      bottom: 8%;
      height: 50px;
      width: 26%;
      background: url("../../assets/images/y3.png") no-repeat;
      background-size: 100% 100%;
    }
    .livelogo {
      height: 88px;
      width: 68%;
      background: url("../../assets/images/liveLogo.png") no-repeat;
      background-size: 100% 100%;
      margin: 10% 0 24% 0;
    }
    .title {
      text-align: center;
      z-index: 9;
    }
    .tip {
      font-size: 12px;
      margin-top: 10px;
      color: #bc1c21;
      transform: scale(0.8);
      position: relative;
      text-align: left;
      margin-left: 80px;
      width: 300px;
      .tiplog {
        height: 32px;
        width: 60px;
        transform: scale(0.8);
        background: url("../../assets/images/jts.png") no-repeat;
        position: absolute;
        left: -48px;
        top: -16px;
      }
    }

    .text {
      margin-top: 30px;
      width: 90%;
      font-size: 18px;
      text-align: center;
    }
    .card {
      display: flex;
      align-items: center;
      border-radius: 8px;
      overflow: hidden;
      margin-top: 32px;
      width: 80%;
      height: 100px;
      background: radial-gradient(circle at right, transparent 18px, #bc1c21 0);
      /*下面是生成的样式*/
      -webkit-mask: radial-gradient(circle at 110px 8px, transparent 8px, #bc1c21 4.5px), radial-gradient(closest-side circle at 50%, #bc1c21 99%, transparent 100%);
      -webkit-mask-size: 100%, 2px 4px;
      -webkit-mask-repeat: repeat, repeat-y;
      -webkit-mask-position: 0 -8px, 110px;
      -webkit-mask-composite: source-out;
      mask-composite: subtract;
    }
    .card-left {
      width: 32%;
      background: url("../../assets/images/shopees.png") no-repeat;
      background-size: 100% 100%;
      height: 88px;
      margin: 4px 10px 0 8px;
    }

    .card-right {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      color: #ffedca;
      font-size: 22px;
      line-height: 20px;
      margin-left: 12px;
      font-family: SourceHanSansCN-Regular !important;
    }
    .card-info {
      margin: 0;
    }
    .card-time {
      margin-top: 4px;
    }
  }
}
</style>
